import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { ApolloLink } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import { InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { getToken } from './get-token';
import { useUserStore } from '../pinia/modules/user';
import { userCookies } from '@/shared/constants/cookies';
import { hobbiiSessionStorage } from './web-storage';
import { initStorefrontApolloClient } from '@/shared/utils/storefront-client';
import { queryCountryConfigs } from '@/shared/graphql/gateway/queries';

const recoveryLink = (onSuccess, onError) =>
  new RetryLink({
    delay: {
      initial: 0
    },
    attempts: {
      max: 2,
      retryIf: async (error) => {
        if (error.statusCode === 401) {
          const token = await getToken(true);
          if (token) {
            onSuccess(token);
          } else {
            onError();
          }
          return !!token;
        }
        return false;
      }
    }
  });

const enrichLink = () =>
  setContext(async (_, { headers }) => {
    const sessionToken = hobbiiSessionStorage.getItem(userCookies.C_ID);

    const authTokenHeader = sessionToken
      ? { Authorization: `Bearer ${sessionToken}` }
      : {};

    return {
      headers: {
        ...headers,
        ...authTokenHeader,
        'Content-Type': 'application/json'
      }
    };
  });

const httpLink = (graphqlHost) =>
  createHttpLink({
    uri: `${graphqlHost}`
  });

const cache = new InMemoryCache({
  typePolicies: {
    CommunityQueries: {
      keyFields: ['__typename']
    }
  }
});

const apolloClientInit = (options) => {
  const userStore = useUserStore();

  const setReceivedCustomer = (token) => {
    userStore.setCustomer(token);
  };

  return new ApolloClient({
    link: ApolloLink.from([
      recoveryLink(setReceivedCustomer, userStore.cleanCustomer),
      enrichLink(),
      httpLink(options.graphqlHost)
    ]),
    cache
  });
};

const getStorefrontApolloClient = async (gatewayApolloClient) => {
  const shopifyDomain = `https://${window.Shopify.shop}`;
  const storeName = window.Shopify.shop.split('.')[0]; // TODO: any smarter way of getting the storeName?
  //

  const countryConfigsResponse = await gatewayApolloClient.query(
    queryCountryConfigs()
  );

  const publicAccessToken =
    countryConfigsResponse.data?.countryConfigs?.find(
      (config) => storeName === config?.shopifyConfig?.storefront?.name
    )?.shopifyConfig?.storefront?.publicAccessToken ?? '';

  // TODO: What happens if we don't have a publicAccessToken? Should we throw? For now notify Bugsnag.
  if (!publicAccessToken) {
    // Features rely on this
    throw new Error(
      `No publicAccessToken received from Gateway for storeName: "${storeName}".`
    );
  }

  return initStorefrontApolloClient(shopifyDomain, publicAccessToken);
};

const getGatewayApolloClient = () =>
  apolloClientInit({
    graphqlHost: import.meta.env.VITE_HOBBII_GATEWAY
  });

const getApolloClients = async () => {
  const gatewayApolloClient = getGatewayApolloClient();

  const storefrontApolloClient =
    await getStorefrontApolloClient(gatewayApolloClient);

  return {
    gateway: gatewayApolloClient,
    storefront: storefrontApolloClient
  };
};

export { apolloClientInit, getApolloClients };
